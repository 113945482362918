import React from 'react'
import './Hero.css'
import Header from '../Header/Header'
import hero_image from '../../assets/hero_image1.png';
import hero_image_back from '../../assets/hero_image_back.png';
import Heart from '../../assets/heart.png';
import Calories from '../../assets/calories.png';
import NumberCounter from "number-counter";

import {motion} from 'framer-motion'
const Hero = () => {
    const transition = {type: 'spring', duration: 3}
    const mobile = window.innerWidth<=768 ? true:false;

    return (
        <div className="hero" id='home'>
            <div className="blur hero-blur"></div>
            <div className="left-h">
                <Header />
                {/* the best ad */}
                <div className="the-best-ad">
                    <motion.div 
                     initial={{left:mobile?'165px':'238px'}}
                     whileInView={{left:'8px'}}
                     transition={{...transition, type:'tween'}}
                    >
                    </motion.div>
                    <span>The Best Fitness club in the town</span>
                </div>

                {/* Hearo Heading */}
                <div className="hero-text">
                    <div>
                        <span className='stroke-text'>Shape </span>
                        <span>Your</span>
                    </div>
                    <div>
                        <span>Ideal Body</span>
                    </div>
                    <div>
                        <span>
                        In here we will help you to shape and build your ideal body and live up your life to fullest
                        </span>
                    </div>
                </div>

                {/* figures */}
                <div className='figures'>
                    <div>
                        <span><NumberCounter 
                        end={10}
                        start={5}
                        delay='4'
                        preFix="+"
                        ></NumberCounter> </span>
                        <span>expert coaches</span>
                    </div>
                    <div>
                        <span><NumberCounter 
                        end={150}
                        start={100}
                        delay='4'
                        preFix="+"
                        ></NumberCounter> </span>
                        <span>members joined</span>
                    </div>
                    <div>
                        <span><NumberCounter 
                        end={20}
                        start={10}
                        delay='4'
                        preFix="+"
                        ></NumberCounter></span>
                        <span>fitness programs</span>
                    </div>
                </div>

                {/* hero button */}
                <div className='hero-btn'>
                   <button className="btn">Get Started</button>
                   <button className="btn">Learn More</button>
                </div>
            </div>

            {/* right hero  */}
            <div className="right-h">
              <button className='btn'>Join Now</button>

              <motion.div className="heart-rate"
               transition={transition}
               whileInView={{right:'4rem'}}
               initial={{right:'-1rem'}}
              >
                <img src={Heart} alt="" />
                <span>Heart Rate</span>
                <span><NumberCounter 
                        end={116}
                        start={80}
                        delay={4}
                        postFix="bpm"
                        ></NumberCounter> </span>
              </motion.div>

              {/* hero image */}
              <img src={hero_image} alt="" className="hero-image" />
              <motion.img
              initial={{right:'11rem'}}
              whileInView={{right:'18rem'}}
              transition={transition}
              src={hero_image_back} alt="" className="hero-image-back" />

              {/* calories */}
              <motion.div className='calories'
              initial={{right:'37rem'}}
              whileInView={{right:'28rem'}}
              transition={transition}

              >
              <img src={Calories} alt="" />
              <div>
              <span>Calories Burned </span>
              <span><NumberCounter 
                        end={220}
                        start={150}
                        delay={4}
                        postFix="kcal"
                        ></NumberCounter></span>
              </div>
              
              </motion.div>

            </div>
        </div>
    )
}

export default Hero